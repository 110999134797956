// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articoli-index-js": () => import("./../../../src/pages/articoli/index.js" /* webpackChunkName: "component---src-pages-articoli-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-chi-sono-index-js": () => import("./../../../src/pages/chi-sono/index.js" /* webpackChunkName: "component---src-pages-chi-sono-index-js" */),
  "component---src-pages-contatti-index-js": () => import("./../../../src/pages/contatti/index.js" /* webpackChunkName: "component---src-pages-contatti-index-js" */),
  "component---src-pages-cookie-policy-index-js": () => import("./../../../src/pages/cookie-policy/index.js" /* webpackChunkName: "component---src-pages-cookie-policy-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-panoramica-index-js": () => import("./../../../src/pages/panoramica/index.js" /* webpackChunkName: "component---src-pages-panoramica-index-js" */),
  "component---src-pages-piccoli-sollievi-cineteca-index-js": () => import("./../../../src/pages/piccoli-sollievi/cineteca/index.js" /* webpackChunkName: "component---src-pages-piccoli-sollievi-cineteca-index-js" */),
  "component---src-pages-piccoli-sollievi-index-js": () => import("./../../../src/pages/piccoli-sollievi/index.js" /* webpackChunkName: "component---src-pages-piccoli-sollievi-index-js" */),
  "component---src-pages-piccoli-sollievi-libreria-index-js": () => import("./../../../src/pages/piccoli-sollievi/libreria/index.js" /* webpackChunkName: "component---src-pages-piccoli-sollievi-libreria-index-js" */),
  "component---src-pages-prestazioni-index-js": () => import("./../../../src/pages/prestazioni/index.js" /* webpackChunkName: "component---src-pages-prestazioni-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-templates-blog-post-template-js": () => import("./../../../src/templates/blogPostTemplate.js" /* webpackChunkName: "component---src-templates-blog-post-template-js" */),
  "component---src-templates-book-page-template-js": () => import("./../../../src/templates/bookPageTemplate.js" /* webpackChunkName: "component---src-templates-book-page-template-js" */),
  "component---src-templates-movie-page-template-js": () => import("./../../../src/templates/moviePageTemplate.js" /* webpackChunkName: "component---src-templates-movie-page-template-js" */)
}

